import * as React from "react"
import { Link } from "gatsby"
import Page from "../containers/page"

const NotFoundPage = () => {
  return (
    <Page>
      <main >
        <div className="text-center mt-36">
          <h1 >Page not found (404)</h1>
          <p>We're sorry, but we couldnt find that page.</p>
          <Link to="/" className="btn">Back To Home Page &uarr;</Link>
        </div>

      </main>
    </Page >
  )
}

export default NotFoundPage
